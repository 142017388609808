import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'

import styles from './josh-overview.module.css'
import { classList } from '../../utility/class-list'

function JoshOverview(props) {
  return (
    <div className={styles.container}>
      <img
        className={classList(styles.image, styles.item)}
        src={imageUrlFor(buildImageObj(props.mainImage))
          .height(300)
          .width(300)
          .url()}
      />
      <div className={styles.item}>
        <h1 className={styles.title}>Hi, I'm Josh!</h1>
        <h2 className={styles.developer}>Software Developer / Musician</h2>
        <p>
          Josh Gibson is a software developer and musician based in Kalamazoo, MI. As a developer,
          Josh has found a home in the TypeScript community, focusing his development and
          contributions on these technologies. As a musician, he displays a broad experience in
          percussion drawing on his experiences in the marching arts, orchestral percussion, and
          solo literature. While Josh works professionally as a software developer with Maestro, he
          is always seeking opportunities to combine his two passions, such as his in-development
          rehearsal application called Practice Liszt.
        </p>
      </div>
    </div>
  )
}

export default JoshOverview
